<template>
  <div>
    <div class="flex md12">

      <va-card :title="'Organizations - Orders'">
        <div class="row align--center">
          <div class="flex xs12 md3">
            <va-input
              :value="term"
              :placeholder="'Search Replies'"
              @input="search"
              removable
            >
              <va-icon
                name="fa fa-search"
                slot="prepend"
              />
            </va-input>
          </div>
          <div class="flex xs12 md3 mb-3">
              <!-- create button and allign right -->
              <va-button
                  @click="CreateOrderForm()"
                  color="primary"
              >
                  <i
                      class="va-icon iconicstroke iconicstroke-bolt"
                      style="margin-right: 1em;"
                  />  Create Order
              </va-button>
          </div>

          <div class=" flex xs12 md3 offset--md3">
              <va-select
              v-model="perPage"
              :label="$t('tables.perPage')"
              :options="perPageOptions"
              no-clear
            />
          </div>
        </div>

        <va-data-table
          :fields="fields"
          :data="filteredData"
          :per-page="parseInt(perPage)"
          :loading="loading"
          clickable
          hoverable
        >
        <template slot="orderSecret" slot-scope="props">
          <span @click="showField('Order Secret', props.rowData.default.orderSecret)" :title="props.rowData.default.orderSecret" >{{ props.rowData.orderSecret }}</span>
          </template>
         <template slot="price" slot-scope="props">
              <span @click="showField('Price', props.rowData.default.price)" :title="props.rowData.default.price" >{{ props.rowData.price }}</span>
        </template>
        <template slot="purchasedSubscriptions" slot-scope="props">
          <span @click="showField('Number of Subscribers', props.rowData.default.purchasedSubscriptions)" :title="props.rowData.default.purchasedSubscriptions" >{{ props.rowData.purchasedSubscriptions }}</span>
        </template>
        <template slot="createdAt" slot-scope="props">
          <span @click="showField('Created At', props.rowData.default.createdAt)" :title="props.rowData.default.createdAt" >{{ props.rowData.createdAt }}</span>
        </template>
        <template slot="actions" slot-scope="props">
          <div class="flex row">
            <!-- style="opacity: 0.5;" -->
            <va-button @click="HardStopOrder(props.rowData)" title="Hard Stop" color="#c18322" icon="fa fa-flag" :style="props.rowData.default.hardStop ? {} : { opacity: 0.5 }"/>

              <va-button @click="EditOrderForm(props.rowData)" title="View/Edit Order"   icon="fa fa-pencil"/>
              <va-button @click="ShowRelatedUsers(props.rowData)" title="Related Users"   icon="fa fa-users"/>
              <va-button @click="DeleteOrderForm(props.rowData.id, props.rowData.default.orderSecret)" title="Delete Order" color="danger" icon="fa fa-trash"/>
          </div>
        </template>
       </va-data-table>



 
        <va-button
            @click="back()"
            color="primary"
            class="mb-1 mt-3"
          >
            <i
              class="va-icon iconicstroke iconicstroke-arrow-left"
              style="margin-right: 1em;"
            />  Back
          </va-button>


      </va-card>

     
      <va-modal
        v-model="RelatedUsersModal"
        hideDefaultActions
        class="flex xs12"
        title="Related Users for Order"
        size="large"
        ok-text="Ok">
          <va-card>
              <va-data-table
                  :fields="relatedUsersField"
                  :data="relatedUsersData"
                  :per-page="parseInt(perPage)"
                  :loading="loading"
                  clickable
                  hoverable
              >
              <template slot="name" slot-scope="props">
                  <span @click="showField('Name', props.rowData.displayName)" :title="props.rowData.displayName" >{{ props.rowData.displayName }}</span>
              </template>
              <template slot="email" slot-scope="props">
                  <span @click="showField('Email', props.rowData.email)" :title="props.rowData.email" >{{ props.rowData.email }}</span>
              </template>
              <template slot="joinDate" slot-scope="props">
                  <span @click="showField('JoinedAt', props.rowData.organizationData.formatedJoinDate)" :title="props.rowData.organizationData.formatedJoinDate" >{{ props.rowData.organizationData.formatedJoinDate }}</span>
              </template>
              <template slot="actions" slot-scope="props">
                  <div class="flex row">
                      <va-button @click="redirectUser(props.rowData)" title="User Page"  color="success"  icon="fa fa-user"/>
                      <va-button @click="UnassignUser(props.rowData)" title="Unassign User"  color="danger"  icon="fa fa-trash"/>
                  </div>
              </template>
              </va-data-table>
            
          </va-card>
      </va-modal>


    </div>
  </div>
</template>

<script>
import {debounce} from 'lodash';
import moment from 'moment';
import axios from '@/scripts/interceptor.js'
import firebase from 'firebase';
export default {
  data() {
    return {
      email: '',
      admins: [],
      loading: true,
      relatedUsers: [],
      currentOrderSecret: null,
      currentOrder : null,
      RelatedUsersModal: false,
      regradeLoading: false,
      emailErrors: [],
      orders: [],
      term: null,
      perPage: '10',
      perPageOptions: ['10', '50', '100', '250'],
      availableTests: [],
      ticketLabels: [],
    };
  },
  async created() {
   await this.loadData();
  },
  computed: {
     relatedUsersField(){
          return [
              {
                  name: '__slot:name',
                  title: 'Name',
                  sortField: 'displayName',
              },
              {
                  name: '__slot:email',
                  title: 'Email',
                  sortField: 'email',
              },
              {
                  name: '__slot:joinDate',
                  title: 'Join Date',
                  sortField: 'joinDate',
              },
              {
              name: '__slot:actions',
              title: 'Actions',
              width: '20%',
              },
          ]
    },
    fields() {
      return [
      {
        name: '__slot:orderSecret',
        title: 'Order Secret (ID)',
        sortField: 'orderSecret',
        width: '15%',
      },
      {
        name: '__slot:price',
        title: 'Price Per Seat (USD)',
        sortField: 'price',
        width: '10%',
      },
      {
        name: 'totalPrice',
        title: 'Total Price',
        sortField: 'totalPrice',
        width: '10%',
      },
      {
        name: '__slot:purchasedSubscriptions',
        title: 'Number of Subscribers',
        sortField: 'purchasedSubscriptions',
        width: '15%',
      },
      {
        name: '__slot:createdAt',
        title: 'Created At',
        sortField: 'createdAt',
        width: '15%',
      },
      {
      name: '__slot:actions',
      title: 'Actions',
      width: '30%',
      },
      ];
    },
    relatedUsersData(){
      return this.relatedUsers
    },
    filteredData() {
      if (!this.term || this.term.length < 1) {
        //order by updatedAt  or createdAt, if createdAt is not available
        this.orders.sort((a, b) => {
              if (a.updatedAt && b.updatedAt) {
                return b.updatedAt - a.updatedAt;
              }
              return b.createdAt - a.createdAt;
        });
        return this.orders;
      }
      return this.orders.filter((item) => {
        let returnUser = false;
        if (item.price) {
          if (item.price.toLowerCase().indexOf(this.term.toLowerCase()) !== -1) {
            returnUser = true;
          }
        }
        if (item.orderSecret) {
          if (item.orderSecret.toLowerCase().indexOf(this.term.toLowerCase()) !== -1) {
            returnUser = true;
          }
        }
        if (item.createdAt) {
          if (item.createdAt.toLowerCase().indexOf(this.term.toLowerCase()) !== -1) {
            returnUser = true;
          }
        }
        return returnUser;
      });
    },
  },
  methods: {
    async back(){
      let uid = this.$route.params.uid;
      this.$router.push({name: 'edit', params: {uid: uid}});
    },
    async loadData(){
     this.loading = true;
     this.orders = [];
      const uid = this.$route.params.uid;
      const orderFormsResult = await axios.get(`/api/organizations/findRelatedOrderForms?uid=${uid}`)
      if(orderFormsResult.data.data){
        this.orders = orderFormsResult?.data?.data;
      }
     this.loading = false;
    },
    showField(title, text) {
      //show swal with the text
      this.$swal({
        title: title,
        icon: 'info',
        html: text,
        confirmButtonText: 'Close',
      });
    },
    createHash(){
      const notAllowedCharacters = ['0', 'S', '5', 'i', 'l', '1'];
      let randomString = '';
      const allowedCharacters = 'ABCDEFGHJKLMNOPQRTUVWXYZ12346789';
      while (randomString.length < 12) {
          const randomChar = allowedCharacters.charAt(Math.floor(Math.random() * allowedCharacters.length));
          if (!notAllowedCharacters.includes(randomChar)) {
              randomString += randomChar;
          }
      }
      return randomString
    },
    validateOrderData(result){
      if(result.value[0].length === 0 || result.value[1].length === 0 || result.value[2].length === 0  || result.value[3].length === 0 || result.value[4].length === 0 || result.value[5].length === 0){
        throw new Error('Please fill out all fields');
      }
      if(isNaN(result.value[1]) || isNaN(result.value[2])){
       throw new Error('Price and Number of Subscribers must be a number');
      }
      //check if endDate is before startDate
      if(result.value[0] > result.value[5]){
        throw new Error('End Date must be after Start Date');
      }
    },
    HardStopOrder(data){
     let hardStop = data.default.hardStop;
     //check if hardStop is false or true, then change the message
     let message = hardStop ? 'The end date of the order will be 1 year from the user registering date, disabling the hard stop.' : 'Hard Stop: Users will be unsubscribed after the informed end date.';
     this.$swal({
        title: 'Are you sure?',
        text: message,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, do it!',
        cancelButtonText: 'No, keep it'
      }).then(async (result) => {
        if (result.value) {
          this.loading = true;
          this.$swal.fire({
              title: 'Saving Data',
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              onOpen: async () => {
                  this.$swal.showLoading();
                  const uid = this.$route.params.uid;
                  //create new Order Form
                  await firebase.firestore().collection('organizations').doc(uid).collection('orderForms').doc(data.id).update({
                    hardStop: !hardStop
                  });
                  this.$swal({
                      icon: 'success',
                      title: 'Order Updated',
                      showConfirmButton: true,
                      timer: 1500
                  }).then(async () => {
                      await this.loadData();
                  });
              },
            })
          this.loading = false;
        }
      })
    },
    CreateOrderForm(){
          //create form for swal with the folowwing fields Start Date, Creation Date, Price, Number of Subscribers
          this.$swal({
            title: 'Create Order',
            html: `
              <label for="swal-input1" style="display: block; float: left; margin-top: 5px; margin-right: 5px; margin-bottom: 0px;">Start Date:</label>
              <input id="swal-input1" value="${new Date().toISOString().slice(0, 10)}" style="margin-top: 0.5em!important;" class="swal2-input" placeholder="Start Date" type="date" required>
    
              <div style="padding-bottom: 20px;text-align: left;padding-top: 2px;display: flex;justify-content: left;align-items: center;">
                <label for="swal-input6" style="display: block; float: left; margin-top: 5px; margin-right: 5px; margin-bottom: 0px;">Has End Date?</label>
                <input type="checkbox" style="width: 30px;margin-top: 5px !important;margin-left: 10px!important;height: 3vh;]: 10px;margin: 0;" id="swal-input7" value="" class="swal2-input" placeholder="Has End Date" type="date" >
              </div>
             
              <div id="swal-div-1" style="display: none;">
                <label for="swal-input6" style="display: block; float: left; margin-top: 5px; margin-right: 5px; margin-bottom: 0px;">End Date (For the case of a Hard Stop):</label>
                <input id="swal-input6" value="${(new Date(new Date().setFullYear(new Date().getFullYear() + 1))).toISOString().slice(0, 10)}" style="margin-top: 0.5em!important;" class="swal2-input" placeholder="End Date" type="date" required>
              </div>

              <label for="swal-input5" style="display: block; float: left; margin-top: 5px; margin-right: 5px; margin-bottom: 0px;">Ordered By:</label>
              <input id="swal-input5" style="margin-top: 0.5em!important;" class="swal2-input" placeholder="John doe" type="text" required>
             
              <div style="display: flex;">
                <div style="flex: 1;">
                  <label for="swal-input2" style="display: block; float: left;">Price Per Seat (USD):</label>
                  <input id="swal-input2" class="swal2-input" placeholder="0.00" step="0.01" pattern="[0-9.]+" type="number" required oninput="updateTotal()">
                </div>
                <div style="flex: 1;">
                  <label for="swal-input3" style="display: block; float: left;">Number of Subscribers:</label>
                  <input id="swal-input3" class="swal2-input" placeholder="0.00" step="1" pattern="[0-9.]+" type="number" required>
                </div>
              </div>
              <label for="swal-input4" style="display: block; float: left; margin-top: 5px; margin-right: 5px; margin-bottom: 0px;">Total Price:</label>
              <input id="swal-input4" style="margin-top: 0.5em!important; " class="swal2-input" placeholder="0.00" type="text" disabled>
            `,
            focusConfirm: false,
            confirmButtonText: 'Create',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            preConfirm: () => {
              return [
                document.getElementById('swal-input1').value,
                document.getElementById('swal-input2').value,
                document.getElementById('swal-input3').value,
                document.getElementById('swal-input4').value,
                document.getElementById('swal-input5').value,
                document.getElementById('swal-input6').value,
                document.getElementById('swal-input7').checked
              ]
            },
            didOpen: () => {
              window.updateTotal = () => {


                  const pricePerSeat = parseFloat(document.getElementById('swal-input2').value) || 0;
                  const numberOfSubscribers = parseFloat(document.getElementById('swal-input3').value) || 0;
                  const totalPrice = pricePerSeat * numberOfSubscribers;
                  document.getElementById('swal-input4').value = totalPrice.toFixed(2);
                }
                document.getElementById('swal-input7').addEventListener('change', () => {
                  if(document.getElementById('swal-input7').checked){
                    document.getElementById('swal-div-1').style.display = 'block';
                  }else{
                    document.getElementById('swal-div-1').style.display = 'none';
                  }
                });
                document.getElementById('swal-input2').addEventListener('input', window.updateTotal);
                document.getElementById('swal-input3').addEventListener('input', window.updateTotal);
              }
            }).then(async (result) => {
            if (result.isConfirmed) {
              //check if any of the inputs are empty
              try {
                this.validateOrderData(result);
              } catch (error) {
                this.$swal({
                  icon: 'error',
                  title: error.message,
                  html: 'Oops, something went wrong! Please try again.',
                  showConfirmButton: true,
                  timer: 1500
                });
                return;
              }
              this.$swal.fire({
                title: 'Saving Data',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                onOpen: async () => {
                    this.$swal.showLoading();
                    const uid = this.$route.params.uid;
                    let currentHash = 'OS' + this.createHash();
                    let endDate = result.value[5] ? result.value[5] : null;
                    if(!result.value[6]){
                      endDate = '2099-12-31';
                    }
                    //create new Order Form
                    await firebase.firestore().collection('organizations').doc(uid).collection('orderForms').add({
                      startDate: result.value[0],
                      endDate: endDate,
                      price: result.value[1],
                      orderSecret:  currentHash,
                      purchasedSubscriptions: result.value[2],
                      orderedBy: result.value[4],
                      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                      enabled: true,
                    });
                    this.$swal({
                        icon: 'success',
                        title: 'Order Created',
                        html: `Order Secret: ${currentHash}`,
                        showConfirmButton: true,
                        timer: 1500
                    }).then(async () => {
                        await this.loadData();
                    });
                },
              })
            }
          });
    },
    EditOrderForm(data) {
          //create form for swal with the folowwing fields Start Date, Creation Date, Price, Number of Subscribers
          this.$swal({
            title: 'Edit Order',
            html: `
              <label for="swal-input1" style="display: block; float: left; margin-top: 5px; margin-right: 5px; margin-bottom: 0px;">Start Date:</label>
              <input id="swal-input1" value="${data.default.startDate}" style="margin-top: 0.5em!important;" class="swal2-input" placeholder="Start Date" type="date" required>
             
              <label for="swal-input6" style="display: block; float: left; margin-top: 5px; margin-right: 5px; margin-bottom: 0px;">End Date: (For the case of a Hard Stop)</label>
              <input id="swal-input6" value="${data.default.endDate}" style="margin-top: 0.5em!important;" class="swal2-input" placeholder="End Date" type="date" required>

              <label for="swal-input5" style="display: block; float: left; margin-top: 5px; margin-right: 5px; margin-bottom: 0px;">Ordered By:</label>
              <input id="swal-input5" value="${data.default.orderedBy}" style="margin-top: 0.5em!important;" class="swal2-input" placeholder="John doe" type="text" required>
             
             
              <div style="display: flex;">
                <div style="flex: 1;">
                  <label for="swal-input2" style="display: block; float: left;">Price Per Seat (USD):</label>
                  <input id="swal-input2" value="${data.default.price}" class="swal2-input" placeholder="0.00" step="0.01" pattern="[0-9.]+" type="number" required oninput="updateTotal()">
                </div>
                <div style="flex: 1;">
                  <label for="swal-input3" style="display: block; float: left;">Number of Subscribers:</label>
                  <input id="swal-input3" value="${data.default.purchasedSubscriptions}"  class="swal2-input" placeholder="0.00" step="1" pattern="[0-9.]+" type="number" required>
                </div>
              </div>
              <label for="swal-input4" style="display: block; float: left; margin-top: 5px; margin-right: 5px; margin-bottom: 0px;">Total Price:</label>
              <input id="swal-input4" style="margin-top: 0.5em!important; " class="swal2-input" placeholder="0.00" type="text" disabled>
            `,
            focusConfirm: false,
            confirmButtonText: 'Update',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            preConfirm: () => {
              return [
                document.getElementById('swal-input1').value,
                document.getElementById('swal-input2').value,
                document.getElementById('swal-input3').value,
                document.getElementById('swal-input4').value,
                document.getElementById('swal-input5').value,
                document.getElementById('swal-input6').value,
              ]
            },
            didOpen: () => {
              window.updateTotalEditArea = () => {
                  const pricePerSeat = parseFloat(document.getElementById('swal-input2').value) || 0;
                  const numberOfSubscribers = parseFloat(document.getElementById('swal-input3').value) || 0;
                  const totalPrice = pricePerSeat * numberOfSubscribers;
                  document.getElementById('swal-input4').value = totalPrice.toFixed(2);
                }
                document.getElementById('swal-input2').addEventListener('input', window.updateTotalEditArea);
                document.getElementById('swal-input3').addEventListener('input', window.updateTotalEditArea);
                // Call updateTotalEditArea to set the initial value for swal-input4
                window.updateTotalEditArea();
              }
            }).then(async (result) => {
            if (result.isConfirmed) {
              //check if any of the inputs are empty
              try {
                this.validateOrderData(result);
              } catch (error) {
                this.$swal({
                  icon: 'error',
                  title: error.message,
                  html: 'Oops, something went wrong! Please try again.',
                  showConfirmButton: true,
                  timer: 1500
                });
                return;
              }
              this.$swal.fire({
                title: 'Saving Data',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                onOpen: async () => {
                   this.$swal.showLoading();
                   const uid = this.$route.params.uid;
                   //create new Order Form
                   await firebase.firestore().collection('organizations').doc(uid).collection('orderForms').doc(data.id).update({
                      startDate: result.value[0],
                      endDate: result.value[5],
                      price: result.value[1],
                      orderedBy: result.value[4],
                      purchasedSubscriptions: result.value[2],
                      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                    });
                    this.$swal({
                        icon: 'success',
                        title: 'Order Updated',
                        showConfirmButton: true,
                        timer: 1500
                    }).then(async () => {
                        await this.loadData();
                    });
                },
              })
            }
          });
    },
    DeleteOrderForm(id, orderSecret){
      this.$swal({
        title: 'Are you sure?',
        text: "The Order will be deleted, and you will not be able to recover it!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, DELETE it!'
      }).then(async (result) => {
          if (result.value) {
              this.$swal.fire({
              icon: 'info',
              title: 'Deleting Order',
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              onOpen: async () => {
                  this.$swal.showLoading();
                  let uid = this.$route.params.uid;
                  //get current id and set enabled to false
                  await firebase.firestore().collection('organizations').doc(uid).collection('orderForms').doc(id).delete();
                  this.$swal.close();
              },
              }).then((result) => {
              this.$swal({
                  icon: 'success',
                  title: 'Order DELETED!',
                  html: `Order Secret: ${orderSecret}`,
                  showConfirmButton: true,
                  timer: 1500
               }).then(async () => {
                  await this.loadData();
               });
              })
          }
      })
    },    
    async ShowRelatedUsers(data){
      let organizationId = this.$route.params.uid;
      let orderSecret = data.default.orderSecret;
      this.currentOrderSecret = null
      this.relatedUsers = [];
      ///Open the swal
      this.$swal.fire({
      icon: 'info',
      title: 'Loading users...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
          this.$swal.showLoading()
      }})

      let response = false
      try {
        response = await axios.get(`/api/organizations/getRelatedOrderForms?organizationID=${organizationId}&orderSecret=${orderSecret}`)
        this.$swal.close()
      } catch (error) {
        this.$swal.fire ({
          icon: 'error',
          title: 'Error',
          text: 'Something went wrong with the connection to the getRelatedOrderForms function!'
        }).then(() => {
          this.$router.push({ name: 'edit', params: { uid: this.$route.params.uid } })
        })
      }

      this.currentOrderSecret = orderSecret
      this.relatedUsers = response?.data?.data
      this.RelatedUsersModal = true
    },    
    async redirectUser(data){
      this.$router.push({ name: 'edituser', params: { uid: data.id } })
    },
    async UnassignUser(data){
      let organizationId = this.$route.params.uid;
      let userID =  data.id;
      let orderSecret = this.currentOrderSecret;
      ///Open the swal
      this.$swal.fire({
      icon: 'info',
      title: 'Unassigning user...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
          this.$swal.showLoading()
      }})
      try {
          let response = await axios.put(`/api/organizations/unasssignUserFromOF`, {
            organizationID: organizationId, userID: userID, orderSecret: orderSecret
          })
          // Close the swal
          this.$swal.close()
          //SHOW SWAL confirming the user was unassigned
          this.$swal({
              icon: 'success',
              title: 'User unassigned!',
              showConfirmButton: true,
              timer: 1500
          }).then(async () => {
              this.currentOrderSecret = null
              // Use a resposta da solicitação
              this.relatedUsers = []
              this.RelatedUsersModal = false  
          });
      } catch (error) {
          // Close the swal
          this.$swal.close()
          //SHOW SWAL confirming the user was unassigned
          this.$swal({
              icon: 'error',
              title: 'Error unassigning user!',
              html: error.message,
              showConfirmButton: true,
              timer: 1500
          });
      }
    },
    search: debounce(function(term) {
      /* eslint-disable */
      this.term = term;
    }, 100),
  }
};
</script>
